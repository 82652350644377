function PrivacyPolicy() {
  return (
    <section
      class='bg-white dark:bg-gray-900 flex flex-col items-center justify-center mx-auto md:h-screen'
      style={{
        backgroundImage: 'url("./apartments.webp")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div class='md:py-8 md:px-4 mx-auto max-w-screen-lg lg:py-16 lg:px-6'>
        <div class='mx-auto max-w-screen-lg'>
          <div class='p-6 bg-white border border-gray-200 md:rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
            <h3 class='text-2xl font-semibold text-gray-900 dark:text-white pb-4'>Políticas de privacidad</h3>
            <div class='overflow-x-hidden md:overflow-y-scroll md:h-modal md:max-h-[40rem]'>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Este documento de Política de privacidad contiene tipos de información recopilada y registrada por
                Tuggar y cómo la usamos. Si tienes preguntas adicionales o requieres más información sobre nuestra
                Política de Privacidad, no dudes en contactarnos.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Esta Política de privacidad se aplica solo a nuestras actividades en línea y es válida para los
                visitantes de nuestro sitio web con respecto a la información que compartieron y/o recopilaron en
                Tuggar. Esta política no se aplica a ninguna información recopilada fuera de línea o a través de canales
                que no sean este sitio web.
              </p>
              <h3 class='text-lg font-semibold text-gray-900 dark:text-white pb-4'>Consentimiento</h3>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Al utilizar nuestro sitio web, usted acepta nuestra Política de privacidad y acepta sus términos.
              </p>
              <h3 class='text-lg font-semibold text-gray-900 dark:text-white pb-4'>Información que recopilamos</h3>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                La información personal que se le solicita que proporcione, y las razones por las que se le solicita que
                la proporcione, se le aclararán en el momento en que le solicitemos que proporcione su información
                personal.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Si se comunica con nosotros directamente, podemos recibir información adicional sobre usted, como su
                nombre, dirección de correo electrónico, número de teléfono, el contenido del mensaje y/o los archivos
                adjuntos que nos envíe, y cualquier otra información que elija proporcionar.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Cuando se registra para una Cuenta, podemos solicitar su información de contacto, incluidos elementos
                como el nombre, el nombre de la empresa, la dirección, la dirección de correo electrónico y el número de
                teléfono.
              </p>
              <h3 class='text-lg font-semibold text-gray-900 dark:text-white pb-4'>Cómo usamos su información</h3>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Usamos la información que recopilamos de varias maneras, incluso para:
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                <ul>
                  <li> - Proporcionar, operar y mantener nuestro sitio web</li>
                  <li> - Mejorar, personalizar y expandir nuestro sitio web</li>
                  <li> - Comprender y analizar cómo utiliza nuestro sitio web</li>
                  <li> - Desarrollar nuevos productos, servicios, características y funcionalidades</li>
                  <li>
                    {' '}
                    - Comunicarnos con usted, ya sea directamente o a través de uno de nuestros socios, incluido el
                    servicio de atención al cliente, para brindarle actualizaciones y otra información relacionada con
                    el sitio web, y con fines promocionales y de marketing
                  </li>
                  <li> - Enviarle correos electrónicos</li>
                  <li> - Encuentre y prevenga el fraude</li>
                </ul>
              </p>
              <h3 class='text-lg font-semibold text-gray-900 dark:text-white pb-4'>Archivos de registro</h3>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Tuggar sigue un procedimiento estándar de uso de archivos de registro. Estos archivos registran a los
                visitantes cuando visitan sitios web. Todas las empresas de alojamiento hacen esto y forman parte del
                análisis de los servicios de alojamiento. La información recopilada por los archivos de registro incluye
                direcciones de protocolo de Internet (IP), tipo de navegador, proveedor de servicios de Internet (ISP),
                marca de fecha y hora, páginas de referencia/salida y posiblemente la cantidad de clics. Estos no están
                vinculados a ninguna información que sea personalmente identificable. El propósito de la información es
                analizar tendencias, administrar el sitio, rastrear el movimiento de los usuarios en el sitio web y
                recopilar información demográfica.
              </p>
              <h3 class='text-lg font-semibold text-gray-900 dark:text-white pb-4'>
                Políticas de privacidad de socios publicitarios
              </h3>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Puede consultar esta lista para encontrar la Política de privacidad de cada uno de los socios
                publicitarios de Tuggar.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Los servidores de anuncios de terceros o las redes de anuncios utilizan tecnologías como cookies,
                JavaScript o balizas web que se utilizan en sus respectivos anuncios y enlaces que aparecen en Tuggar,
                que se envían directamente al navegador de los usuarios. Reciben automáticamente su dirección IP cuando
                esto ocurre. Estas tecnologías se utilizan para medir la efectividad de sus campañas publicitarias y/o
                para personalizar el contenido publicitario que ve en los sitios web que visita.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Tenga en cuenta que Tuggar no tiene acceso ni control sobre estas cookies que utilizan los anunciantes
                de terceros.
              </p>
              <h3 class='text-lg font-semibold text-gray-900 dark:text-white pb-4'>
                Políticas de privacidad de terceros
              </h3>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                La Política de privacidad de Tuggar no se aplica a otros anunciantes o sitios web. Por lo tanto, le
                recomendamos que consulte las Políticas de privacidad respectivas de estos servidores de anuncios de
                terceros para obtener información más detallada. Puede incluir sus prácticas e instrucciones sobre cómo
                excluirse de ciertas opciones.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Puede optar por deshabilitar las cookies a través de las opciones de su navegador individual. Para
                conocer información más detallada sobre la gestión de cookies con navegadores web específicos, se puede
                encontrar en los sitios web respectivos de los navegadores.
              </p>
              <h3 class='text-lg font-semibold text-gray-900 dark:text-white pb-4'>
                Derechos de privacidad de CCPA (No vender mi información personal)
              </h3>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Según la CCPA, entre otros derechos, los consumidores de California tienen derecho a:
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Solicitar que una empresa que recopila datos personales de un consumidor divulgue las categorías y
                piezas específicas de datos personales que una empresa ha recopilado sobre los consumidores.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Solicitar que una empresa elimine cualquier dato personal sobre el consumidor que haya recopilado una
                empresa.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Solicitar que una empresa que vende los datos personales de un consumidor, no venda los datos personales
                del consumidor.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Si realiza una solicitud, tenemos un mes para responderle. Si desea ejercer alguno de estos derechos,
                póngase en contacto con nosotros.
              </p>
              <h3 class='text-lg font-semibold text-gray-900 dark:text-white pb-4'>
                Derechos de protección de datos RGPD
              </h3>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Nos gustaría asegurarnos de que conoce todos sus derechos de protección de datos. Todo usuario tiene
                derecho a lo siguiente:
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Derecho de acceso: tiene derecho a solicitar copias de sus datos personales. Es posible que le cobremos
                una pequeña tarifa por este servicio.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                El derecho a la rectificación: tiene derecho a solicitar que corrijamos cualquier información que crea
                que es inexacta. También tiene derecho a solicitar que completemos la información que cree que está
                incompleta.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                El derecho a borrar: tiene derecho a solicitar que eliminemos sus datos personales, bajo ciertas
                condiciones.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                El derecho a restringir el procesamiento: tiene derecho a solicitar que restrinjamos el procesamiento de
                sus datos personales, bajo ciertas condiciones.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                El derecho a oponerse al procesamiento: tiene derecho a oponerse a que procesemos sus datos personales,
                bajo ciertas condiciones.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                El derecho a la portabilidad de datos: tiene derecho a solicitar que transfiramos los datos que hemos
                recopilado a otra organización, o directamente a usted, bajo ciertas condiciones.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Si realiza una solicitud, tenemos un mes para responderle. Si desea ejercer alguno de estos derechos,
                póngase en contacto con nosotros.
              </p>
              <h3 class='text-lg font-semibold text-gray-900 dark:text-white pb-4'>Información para niños</h3>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Otra parte de nuestra prioridad es agregar protección para los niños mientras usan Internet. Alentamos a
                los padres y tutores a observar, participar y/o monitorear y guiar su actividad en línea.
              </p>
              <p class='text-base leading-relaxed text-gray-500 dark:text-gray-400 pb-4'>
                Tuggar no recopila a sabiendas ninguna información de identificación personal de niños menores de 13
                años. Si cree que su hijo proporcionó este tipo de información en nuestro sitio web, le recomendamos
                encarecidamente que se comunique con nosotros de inmediato y haremos todo lo posible para eliminar de
                inmediato dicha información de nuestros registros.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
