import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, Timestamp } from 'firebase/firestore/lite';

const firebaseConfig = {
    apiKey: "AIzaSyCIDJsHMncilQ1WEVjlJKm6X0jDSuHP6LU",
    authDomain: "tuggar-3d5a5.firebaseapp.com",
    projectId: "tuggar-3d5a5",
    storageBucket: "tuggar-3d5a5.appspot.com",
    messagingSenderId: "417905936629",
    appId: "1:417905936629:web:946f7016200dad3eab9dbc",
    measurementId: "G-TDG3T02VLV"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const addSubscription = async (email) => {
  await addDoc(collection(db, 'pre-launch-subscriptions'), { email, date: Timestamp.now() });
}