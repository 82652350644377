import './App.css';
import * as React from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';

import Newsletter from './newsletter';
import PrivacyPolicy from './privacy-policy';

const router = createBrowserRouter([
  {
    path: '/',
    Component: Newsletter,
  },
  {
    path: '/privacy-policy',
    Component: PrivacyPolicy,
  },
  {
    path: '*',
    exact: true,
    Component: () => <Navigate replace to="/"/>,
  },
]);

function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
