import { useCallback, useState } from 'react';
import { addSubscription } from './firebase';
import { Toast } from 'flowbite-react';

function Newsletter() {
  const [email, setEmail] = useState('');
  const [creating, setCreating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const create = useCallback(async () => {
    try {
      setCreating(true);
      setSuccess(false);
      setFailure(false);
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        await addSubscription(email);
        setEmail('');
        setSuccess(true);
      } else {
        throw new Error('Email no válido');
      }
    } catch (ex) {
      setFailure(true);
    } finally {
      setCreating(false);
    }
  }, [email]);
  return (
    <section
      class='bg-white dark:bg-gray-900 flex flex-col items-center h-screen'
      style={{
        backgroundImage: 'url("./apartments.webp")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div class='mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
        <div class='mx-auto max-w-screen-md sm:max-w-full'>
          <div class='p-6 bg-white border border-gray-200 md:rounded-l-lg shadow dark:bg-gray-800 dark:border-gray-700 h-screen top-0 right-0 fixed flex flex-col justify-center'>
            <h2 class='mb-4 text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl dark:text-white'>
              Tuggar
            </h2>
            <p class='mx-auto mb-8 max-w-2xl font-light text-gray-500 md:mb-12 sm:text-xl dark:text-gray-400'>
              La mejor herramienta para agentes en bienes raíces de la República Dominicana. ¿Te gustaría conocer más?
            </p>
            <form action='#'>
              <div class='mx-auto mb-3 space-y-4 sm:flex sm:space-y-0'>
                <div class='relative w-full'>
                  <label for='email' class='hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'>
                    Email
                  </label>
                  <div class='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'>
                    <svg
                      class='w-5 h-5 text-gray-500 dark:text-gray-400'
                      fill='currentColor'
                      viewBox='0 0 20 20'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z'></path>
                      <path d='M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z'></path>
                    </svg>
                  </div>
                  <input
                    class='block p-3 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                    placeholder='Correo Electrónico'
                    type='email'
                    id='email'
                    required=''
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    disabled={creating}
                  />
                </div>
                <div>
                  <button
                    type='submit'
                    class={`py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg border cursor-pointer ${
                      creating || !email ? 'bg-stone-950' : 'bg-stone-700'
                    } border-stone-600 sm:rounded-none sm:rounded-r-lg hover:bg-stone-800 focus:ring-4 focus:ring-stone-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
                    onClick={create}
                    disabled={creating || !email}
                  >
                    Subscríbete
                  </button>
                </div>
              </div>
              <div class='mx-auto text-sm text-left text-gray-500 newsletter-form-footer dark:text-gray-300'>
                Nos importa la protección de tus datos.{' '}
                <a
                  href='/privacy-policy'
                  class='font-medium text-stone-600 dark:text-primary-500 hover:underline cursor-pointer '
                >
                  Lee nuestras políticas de privacidad
                </a>
                .
              </div>
            </form>
          </div>
        </div>
      </div>
      {success && (
        <Toast className='fixed md:left-5 md:top-5 md:bottom-auto bottom-5 left-none bg-green-700 md:bg-white'>
          <div className='z-50 inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200'>
            <svg
              class='w-5 h-5'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 20 20'
            >
              <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
            </svg>
            <span class='sr-only'>Check icon</span>
          </div>
          <div className='ml-3 text-sm font-normal text-white md:text-gray-500'>¡Te has subscrito!</div>
          <Toast.Toggle
            className='bg-green-700 md:bg-white text-white md:text-gray-500'
            onDismiss={() => setSuccess(false)}
          />
        </Toast>
      )}
      {failure && (
        <Toast className='fixed md:left-5 md:top-5 md:bottom-auto bottom-5 left-none bg-red-700 md:bg-white'>
          <div className='z-50 inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg text-red-500 bg-red-100 dark:bg-red-800 dark:text-red-200'>
            <svg
              class='w-5 h-5'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 20 20'
            >
              <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z' />
            </svg>
            <span class='sr-only'>Error icon</span>
          </div>
          <div className='ml-3 text-sm font-normal text-white md:text-gray-500'>¡Ha ocurrido un error!</div>
          <Toast.Toggle
            className='bg-red-700 md:bg-white text-white md:text-gray-500'
            onDismiss={() => setFailure(false)}
          />
        </Toast>
      )}
    </section>
  );
}

export default Newsletter;
